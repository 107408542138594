import React from "react"
import { Flex, Box, Text, chakra } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    flexDirection: { base: "column", md: "column", lg: "row" },
    height: { base: "100%", md: "100%", lg: "538px" },
    alignItems: "center",
    px: "24px",
    justifyContent: "center",
    width: "100%",
  },
  image: {
    mt: { base: "40px", md: "40px", lg: "0px" },
    minWidth: "160px",
    minHeight: "160px",
    width: "160px",
    height: "160px",
  },
  "text-box": {
    mt: { base: "40px", md: "40px", lg: "0px" },
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "656px" },
    ml: { base: "0px", md: "0px", lg: "46px" },
    mb: { base: "80px", md: "80px", lg: "0px" },
  },
  text: {
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
}

const SectionQuote = () => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/caremeasurement/steve.png"
          alt="Photo of doctor"
          placeholder="blurred"
        />
      </Box>
      <Flex sx={styles["text-box"]}>
        <Text sx={styles.text}>
          “We have been working with Avant-garde since 2016 and there is{" "}
          <chakra.span sx={styles.bold}>no other company like them</chakra.span>{" "}
          that I have seen with their ability to robustly examine our care
          delivery and identify how we can continue to do better in a way{" "}
          <chakra.span sx={styles.bold}>
            that resonates with our surgeons
          </chakra.span>
          . Their attention to detail, and{" "}
          <chakra.span sx={styles.bold}>
            commitment to squeezing every bit of healthcare value is
            incomparable
          </chakra.span>
          ."
        </Text>
        <Text sx={styles.text} mt="24px">
          – Steven F. Schutzer, M.D.
        </Text>
        <Text sx={styles.text} mt="24px">
          Physician Executive, Orthopedic Service Line, Trinity Health Of New
          England Physician Director, Connecticut Joint Replacement Institute at
          Saint Francis
        </Text>
      </Flex>
    </Flex>
  )
}

export default SectionQuote
