import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading } from "@chakra-ui/react"
import Step from "./step"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "712px" },
    width: "100%",
    px: "24px",
  },
  "heading-box": {
    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "80px" },
    maxWidth: { base: "100%", md: "100%", lg: "1340px" },
    justifyContent: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#000000",
  },
  steps: {
    margin: "0 auto",
    mt: { base: "53px", md: "53px", lg: "80px" },
    mb: { base: "120px", md: "120px", lg: "0px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: "space-between",
    maxWidth: { base: "100%", md: "100%", lg: "1340px" },
    height: { base: "100%", md: "100%", lg: "424px" },
  },
  step: {
    root: {
      width: { base: "327px", md: "327px", lg: "248px" },
      "&:nth-child(2)": {
        my: { base: "24px", md: "24px", lg: "0px" },
        mx: { base: "0px", md: "0px", lg: "24px" },
      },
      "&:nth-child(4)": {
        my: { base: "24px", md: "24px", lg: "0px" },
        mx: { base: "0px", md: "0px", lg: "24px" },
      },
    },
  },
}

const SectionHowItWorks = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>How CareMeasurement Works</Heading>
    </Flex>
    <Flex sx={styles.steps}>
      <Step
        styles={styles.step}
        title="Data Types"
        text={["Client data", "National claims data", "AGH network data"]}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-database.png"
            alt="Database icon"
            placeholder="blurred"
          />
        )}
      />
      <Step
        styles={styles.step}
        title="Algorithms and Calculations"
        text={[
          "Clean data",
          "Risk adjust",
          "Catalog supplies",
          "Do Time-Driven Activity-Based Costing",
        ]}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-stats.png"
            alt="Statistics icon"
            placeholder="blurred"
          />
        )}
      />
      <Step
        styles={styles.step}
        title="Software"
        text={["Web hosted", "Fully built by AGH", "Intuitive to use"]}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-dev.png"
            alt="Software developer icon"
            placeholder="blurred"
          />
        )}
      />
      <Step
        styles={styles.step}
        title="Expert Guidance"
        text={[
          "Monthly or quarterly calls to help facilitate improvement plans",
        ]}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-coach.png"
            alt="Coach icon"
            placeholder="blurred"
          />
        )}
      />
      <Step
        styles={styles.step}
        title="Automatic Progress Tracking"
        text={["Quantify improvements over time"]}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-clipboard.png"
            alt="Clipboard icon"
            placeholder="blurred"
          />
        )}
      />
    </Flex>
  </Box>
)

export default SectionHowItWorks
