import React from "react"
import { Flex, Box, Heading, Text } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    flexDirection: "column",
    alignItems: "center",
    width: { base: "327px", md: "327px", lg: "367px" },
    height: "308px",
    px: "32px",
    pb: "32px",
    mt: { base: "24px", md: "64px" },
    borderRadius: "8px",
    border: "1px solid #DCE0E5",
  },
  "heading-box": {
    mt: "24px",
    height: "68px",
    width: "303px",
    justifyContent: "center",
  },
  heading: {
    fontWeight: { base: "500", md: "500", lg: "700" },
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "34px" },
    color: "#041424",
    textAlign: "center",
  },
  image: {
    mt: "24px",
    width: "80px",
    height: "80px",
  },
  "text-box": {
    width: "100%",
    mt: "40px",
  },

  text: {
    textAlign: "center",
    fontSize: "146x",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
}

const Detail = ({ Image, title, text, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <Box sx={_styles.image}>
        <Image />
      </Box>
      <Flex sx={_styles["heading-box"]}>
        <Heading sx={_styles.heading}>{title}</Heading>
      </Flex>
      <Box sx={_styles["text-box"]}>
        {text?.map(t => (
          <Text key={t} sx={_styles.text}>
            {t}
          </Text>
        ))}
      </Box>
    </Flex>
  )
}

export default Detail
