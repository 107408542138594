import React from "react"

import Layout from "../components/layout"
import SectionHero from "../components/caremeasurement/section-hero"
import SectionSeeDetails from "../components/caremeasurement/section-see-details"
import SectionCompareFindings from "../components/caremeasurement/section-compare-findings"
import SectionDevelopPlaybooks from "../components/caremeasurement/section-develop-playbooks"
import SectionInsights from "../components/caremeasurement/section-insights"
import SectionHowItWorks from "../components/caremeasurement/section-how-it-works"
import SectionQuestions from "../components/caremeasurement/section-questions"
import SectionQuote from "../components/caremeasurement/section-quote"
import SectionBestCompanies from "../components/caremeasurement/section-best-companies"

class CareMeasurementIndex extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title={"Healthcare Data Analytics Software | Avant-Garde Health"}
      >
        <SectionHero />
        <SectionSeeDetails />
        <hr
          style={{
            size: "1px",
            width: { base: "100%", md: "100%", lg: "1112px" },
            margin: "0 auto",
            backgroundColor: "#E0E0E0",
          }}
        />
        <SectionCompareFindings />
        <hr
          style={{
            size: "1px",
            width: { base: "100%", md: "100%", lg: "1112px" },
            margin: "0 auto",
            backgroundColor: "#E0E0E0",
          }}
        />
        <SectionDevelopPlaybooks />
        <SectionInsights />
        <SectionHowItWorks />
        <SectionQuestions />
        <SectionQuote />
        <SectionBestCompanies />
      </Layout>
    )
  }
}

export default CareMeasurementIndex
