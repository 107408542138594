import React from "react"
import { Flex, Box } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import IconText from "./icon-text"

const styles = {
  root: {
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    mt: "80px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  "q-and-a-box": {
    mt: { base: "28px", md: "28px", lg: "0px" },
    flexDirection: "column",
    justifyContent: "space-around",
  },
  question: {
    root: { fontWeight: "bold" },
  },
  answer: {
    root: { mt: "24px" },
  },
  image: {
    ml: { base: "0px", md: "0px", lg: "20px" },
    minWidth: { base: "327px", md: "327px", lg: "430px" },
    minHeight: { base: "256px", md: "256px", lg: "335px" },
    width: { base: "327px", md: "327px", lg: "430px" },
    height: { base: "256px", md: "256px", lg: "335px" },
  },
}

const Question = ({ text }) => (
  <IconText
    styles={styles.question}
    text={text}
    Image={() => (
      <StaticImage
        src="../../images/caremeasurement/cm-question.png"
        alt="Question mark icon"
        placeholder="blurred"
      />
    )}
  />
)

const Answer = ({ text }) => (
  <IconText
    styles={styles.answer}
    text={text}
    Image={() => (
      <StaticImage
        src="../../images/caremeasurement/cm-light-bulb.png"
        alt="Light-bulb icon"
        placeholder="blurred"
      />
    )}
  />
)

const QuestionAndAnswer = ({ Image, question, answer }) => {
  return (
    <Flex sx={styles.root}>
      <Flex sx={styles["q-and-a-box"]}>
        <Question text={question} />
        <Answer text={answer} />
      </Flex>
      <Box sx={styles.image}>
        <Image />
      </Box>
    </Flex>
  )
}

export default QuestionAndAnswer
