import React from "react"
import { Flex, Box, Heading, Text } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    flexDirection: "column",
    alignItems: "center",
    width: "248px",
    height: "424px",
    bg: "#E2F5E3",
    px: "24px",
    pb: "24px",
    borderRadius: "8px",
  },
  "heading-box": {
    mt: "30px",
    height: "60px",
    width: "100%",
    justifyContent: "center",
  },
  heading: {
    color: "#00000",
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
  },
  image: {
    mt: "24px",
    width: "80px",
    height: "80px",
  },
  text: {
    mt: "24px",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
}

const Step = ({ Image, title, text, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <Flex sx={_styles["heading-box"]}>
        <Heading sx={_styles.heading}>{title}</Heading>
      </Flex>
      <Box sx={_styles.image}>
        <Image />
      </Box>
      {text?.map(t => (
        <Text key={t} sx={_styles.text}>
          {t}
        </Text>
      ))}
    </Flex>
  )
}

export default Step
