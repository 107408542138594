import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import Card from "../card"

const styles = {
  root: {
    backgroundColor: "#ECEFF7",
    height: { base: "100%", md: "100%", lg: "632px" },
    width: "100%",
    px: "24px",
  },
  "heading-box": {
    margin: "0 auto",
    mt: { base: "24px", md: "24px", lg: "80px" },
    maxWidth: { base: "100%", md: "100%", lg: "1000px" },
    justifyContent: "center",
  },
  heading: {
    mt: { base: "32px", md: "32px", lg: "64px" },
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#000000",
  },
  cards: {
    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "80px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: "space-between",
    maxWidth: { base: "100%", md: "100%", lg: "1340px" },
  },
  card: {
    root: {
      alignItems: "center",
      justifyContent: "center",
      px: "40px",
      width: "100%",
      height: "100%",
      bg: "transparent",
      "&:nth-child(2)": {
        mx: { base: "0px", md: "0px", lg: "20px" },
        mt: { base: "64px", md: "64px", lg: "0px" },
      },
      "&:nth-child(3)": {
        my: { base: "64px", md: "64px", lg: "0px" },
      },
    },
    icon: {
      textAlign: "center",
      width: "160px",
      height: "160px",
      bg: "#CEE7FF",
      borderRadius: "50%",
      padding: "40px",
    },
  },
  "card-title": {
    mt: "40px",
    textAlign: "center",
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    fontWeight: "bold",
    color: "#000000",
  },
  "card-text": {
    textAlign: "center",
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
}
const SectionInsights = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>
        Discover Healthcare Data Analytics Opportunities in Three Critical Areas
      </Heading>
    </Flex>
    <Flex sx={styles.cards}>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-graph.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Flex width="100%" justifyContent="center">
          <Heading sx={styles["card-title"]}>Financial Insights</Heading>
        </Flex>
        <Text sx={styles["card-text"]}>
          Analyze true personnel & supply costs utilizing
          Time-Driven-Activity-Based Costing (TDABC) to identify key drivers for
          cost-savings.
        </Text>
      </Card>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-doctor.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Flex width="100%" justifyContent="center">
          <Heading sx={styles["card-title"]}>Clinical Insights </Heading>
        </Flex>
        <Text sx={styles["card-text"]}>
          Dive deeper into outcomes such as length of stay, readmissions,
          complications, and patient reported outcomes to improve clinical
          practices.
        </Text>
      </Card>
      <Card
        styles={styles.card}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-cogwheel.png"
            alt="Physician"
            placeholder="blurred"
          />
        )}
      >
        <Flex width="100%" justifyContent="center">
          <Heading sx={styles["card-title"]}>Operational Insights</Heading>
        </Flex>
        <Text sx={styles["card-text"]}>
          Look at key metrics like OR times and discharge disposition to
          streamline the care process and optimize post-acute care for your
          patients.
        </Text>
      </Card>
    </Flex>
  </Box>
)

export default SectionInsights
