import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  chakra,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react"
import Detail from "./detail"

const styles = {
  root: {
    height: "100%",
    width: "100%",
    px: "24px",
    mt: { base: "32px", md: "32px", lg: "64px" },
    mb: "65px",
    textAlign: "center",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },

  heading: {
    fontWeight: "700",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#041424",
    mb: { base: "32px", md: "32px", lg: "48px" },
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
    mb: "10px",
  },
  bold: {
    fontWeight: "bold",
  },
  "title-text": {
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    color: "#041424",
    mb: "15px",
  },
  "text-box": {
    pt: { base: "80px", md: "80px", lg: "0px" },
    pb: { base: "40px", md: "40px", lg: "0px" },
    width: "100%",
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  "detail-box": {
    gridColumn: { base: "span 1", md: "span 2", xl: "span 2" },

    "&:last-child:nth-child(3n - 1)": {
      gridColumnEnd: { base: "auto", md: 4, xl: -2 },
    },
    "&:nth-last-child(2):nth-child(3n + 1)": {
      gridColumnEnd: { base: "auto", md: 0, xl: 4 },
    },
    "&:last-child": {
      gridColumnEnd: { base: "auto", md: 4, xl: "auto" },
    },
  },
}
const navigateToDemo = () => navigate("/schedule-a-demo")

const SectionDevelopPlaybooks = () => {
  return (
    <Box id="develop-the-playbooks" sx={styles.root}>
      <Flex sx={styles["text-box"]}>
        <Heading sx={styles.heading}> Develop the Playbooks</Heading>

        <Box sx={{ width: { base: "100%", md: "100%", lg: "50%" } }}>
          <chakra.span sx={styles.text}>Just great.</chakra.span>
          <Text sx={{ mb: "30px" }}></Text>
          <Text sx={styles.text}>
            Everyone agrees that changes need to be made. But no one agrees on
            how to make them. And the more you talk with the leaders involved,
            the more you begin to realize that they don't even see eye-to-eye on
            what the changes should be. You've got different doctors,
            departments and hospitals that have to get on board, but it's tough
            when everyone has their own priorities and no one has a solid plan.
          </Text>
          <Text sx={{ mb: "20px" }}></Text>
          <Text>
            <chakra.span sx={styles.bold}>
              With Avant-garde Health, you can develop the playbooks.{" "}
            </chakra.span>
            Get help turning all these insights into specific action steps from
            a team that's done this many times before.
          </Text>
        </Box>
      </Flex>
      <Grid
        justifyItems="center"
        alignItems="center"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(4, 1fr)",
          xl: "repeat(6, 1fr)",
        }}
        width={{ base: "100%", md: "100%", xl: "1200px" }}
        margin="0 auto"
        mb="32px"
      >
        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-recommendations.png"
                alt="Get personalized recommendations and ongoing help icon"
                placeholder="blurred"
              />
            )}
            title="Get personalized recommendations and ongoing help"
            text={[
              "during monthly or quarterly calls with a dedicated Customer Success team",
            ]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-create-plan.png"
                alt="Create a plan to systematically share analyticsicon"
                placeholder="blurred"
              />
            )}
            title="Create a plan to systematically share analytics"
            text={["throughout the organization on a regular basis"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-savings.png"
                alt="Prioritize the right savings opportunities icon"
                placeholder="blurred"
              />
            )}
            title="Prioritize the right savings opportunities"
            text={[" to see actual costs at the item level"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-track.png"
                alt="Set specific, measurable goals for improvement icon"
                placeholder="blurred"
              />
            )}
            title="Set specific, measurable goals for improvement"
            text={["based on real insights from data"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-drive-improvement.png"
                alt="Drive clinical improvement icon"
                placeholder="blurred"
              />
            )}
            title=" Drive clinical improvement "
            text={[
              "with help from a team that knows how to turn analytics into action",
            ]}
          />
        </GridItem>
      </Grid>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "211px",
          height: "56px",
          margin: "0 auto",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToDemo}
        >
          Take a closer look
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionDevelopPlaybooks
