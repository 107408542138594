import React from "react"
import { navigate } from "gatsby"
import {
  chakra,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import Detail from "./detail"

const styles = {
  root: {
    height: "100%",
    width: "100%",
    px: "24px",
    mt: { base: "32px", md: "32px", lg: "64px" },
    mb: "65px",
    textAlign: "center",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },

  heading: {
    fontWeight: "700",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#041424",
    mb: { base: "32px", md: "32px", lg: "48px" },
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
    mb: "10px",
  },
  bold: {
    fontWeight: "bold",
  },
  "title-text": {
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    fontWeight: { base: "500", md: "500", lg: "700" },
    color: "#041424",
    mb: "15px",
  },
  "text-box": {
    pt: { base: "80px", md: "80px", lg: "0px" },
    pb: { base: "40px", md: "40px", lg: "0px" },
    width: "100%",
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
  },
  "detail-box": {
    gridColumn: { base: "span 1", md: "span 2", xl: "span 2" },

    "&:last-child:nth-child(3n - 1)": {
      gridColumnEnd: { base: "auto", md: 4, xl: -2 },
    },
    "&:nth-last-child(2):nth-child(3n + 1)": {
      gridColumnEnd: { base: "auto", md: 0, xl: 4 },
    },
    "&:last-child": {
      gridColumnEnd: { base: "auto", md: 4, xl: "auto" },
    },
  },
}

const navigateToDemo = () => navigate("/schedule-a-demo")

const SectionCompareFindings = () => {
  return (
    <Box id="compare-the-findings" sx={styles.root}>
      <Flex sx={styles["text-box"]}>
        <Heading sx={styles.heading}> Compare the Findings</Heading>

        <Box sx={{ width: { base: "100%", md: "100%", lg: "50%" } }}>
          <Text sx={styles["title-text"]}>
            Your organization is doing well overall. Probably.
          </Text>
          <Text sx={styles.text}>
            It's hard to tell when you don't have a true apples-to-apples
            comparison of how your costs and clinical outcomes stack up against
            your peers. After all, it's not like other doctors, departments,
            hopitals, and healthcare systems are going out of their way to
            publish all their clinical, financial, and operational data just so
            you can benchmark against it. But without that kind of benchmark
            data, how are you supposed to know what's really working, what
            isn't, and what opportunities you're just completely missing?
          </Text>
          <Text sx={{ mb: "20px" }}></Text>
          <Text>
            <chakra.span sx={styles.bold}>
              With Avant-garde Health, you can compare the findings.{" "}
            </chakra.span>
            Benchmark the data across your department, your hospital, your
            health system and others like yours.
          </Text>
        </Box>
      </Flex>
      <Grid
        justifyItems="center"
        alignItems="center"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(4, 1fr)",
          xl: "repeat(6, 1fr)",
        }}
        width={{ base: "100%", md: "100%", xl: "1200px" }}
        margin="0 auto"
        mb="32px"
      >
        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-improvement.png"
                alt="Get a more complete picture icon"
                placeholder="blurred"
              />
            )}
            title="Uncover new opportunities for improvement"
            text={["by benchmarking everything from costs to outcomes"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-peer-performance.png"
                alt="Let physicians track performance against peers icon"
                placeholder="blurred"
              />
            )}
            title="Let physicians track performance against peers"
            text={["both inside and outside the hospital"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-costs-aligned.png"
                alt="Keep cost and contracts aligned icon"
                placeholder="blurred"
              />
            )}
            title="Keep cost and contracts aligned"
            text={[" across doctors, departments, and facilities"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]} justifySelf="center">
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-change-behavior.png"
                alt="Help clinical teams change behaviors icon"
                placeholder="blurred"
              />
            )}
            title="Help clinical teams change behaviors"
            text={["by providing empirical data they can trust"]}
          />
        </GridItem>

        <GridItem sx={styles["detail-box"]}>
          <Detail
            Image={() => (
              <StaticImage
                src="../../images/caremeasurement/cm-org-peformance.png"
                alt="Compare your organizations performance icon"
                placeholder="blurred"
              />
            )}
            title="Compare your organization's performance "
            text={["against the best-performing providers in the country"]}
          />
        </GridItem>
      </Grid>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          width: "211px",
          height: "56px",
          margin: "0 auto",
        }}
      >
        <Button
          title="Click here to take a closer look at our healthcare data analytics software in action."
          sx={styles.button}
          onClick={navigateToDemo}
        >
          Take a closer look
        </Button>
      </Flex>
    </Box>
  )
}

export default SectionCompareFindings
