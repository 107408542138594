import React from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"
import QuestionAndAnswer from "./question-and-answer"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "1373px" },
    width: "100%",
    px: "24px",
  },
  "heading-box": {
    margin: "0 auto",
    maxWidth: { base: "100%", md: "100%", lg: "1340px" },
    justifyContent: "center",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#000000",
  },
  body: {
    margin: "0 auto",
    mb: { base: "80px", md: "80px", lg: "0px" },
    flexDirection: "column",
    alignItems: "center",
    maxWidth: { base: "100%", md: "100%", lg: "1340px" },
  },
}

const questionA = [
  "Where are the biggest financial opportunities in our organization?",
]
const questionB = [
  "How do I as a physician perform and compare to my peers in my organization and peer group?",
]
const questionC = [
  "How can we turn the insights and opportunities for improvement into action?",
]

const answerA = [
  "Uncover targeted opportunities to reduce costs and save on personnel and supplies based on our analysis of your actual spending and operations.",
  "Our Time-Driven Activity-Based Costing (TDABC) methodology is automated in our platform to accurately measure the costs of health care resources used as the patient moves through the care pathway.",
]
const answerB = [
  "Measure physician performance across the key financial, clinical, and operational metrics in our platform.  This allows you to have open communication with clinical teams and provides the empirical data that can change behavior and create stronger physician engagement.",
  "Leverage our benchmarking within your organization and compare your performance against other top-performing organizations around the country.",
]
const answerC = [
  "Your success is our #1 priority and we have built a strong Customer Success Team that will help you through initial and ongoing platform training, identification and prioritization of opportunities, and regular meetings with organizational, service line, and physician leadership.",
  "Leverage our years of experience working with similar organizations to create the playbook to execute on the opportunities and drive real tangible results.",
]

const SectionQuestions = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["heading-box"]}>
      <Heading sx={styles.heading}>
        Answer Your Key Questions with Avant-garde Health
      </Heading>
    </Flex>
    <Flex sx={styles.body}>
      <QuestionAndAnswer
        question={questionA}
        answer={answerA}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-persona-a.png"
            alt="Physician icon"
            placeholder="blurred"
          />
        )}
      />
      <QuestionAndAnswer
        question={questionB}
        answer={answerB}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-persona-b.png"
            alt="Physician icon"
            placeholder="blurred"
          />
        )}
      />
      <QuestionAndAnswer
        question={questionC}
        answer={answerC}
        Image={() => (
          <StaticImage
            src="../../images/caremeasurement/cm-persona-c.png"
            alt="Physician icon"
            placeholder="blurred"
          />
        )}
      />
    </Flex>
  </Box>
)

export default SectionQuestions
