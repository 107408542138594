import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    alignItems: "center",
    width: "100%",
  },
  image: {
    minWidth: "80px",
    minHeight: "80px",
    width: "80px",
    height: "80px",
  },
  "text-box": {
    ml: "35px",
    flexDirection: "column",
  },
  text: {
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
}

const IconText = ({ Image, text, styles }) => {
  const _styles = merge({}, defaultStyles, styles)
  return (
    <Flex sx={_styles.root}>
      <Box sx={_styles.image}>
        <Image />
      </Box>
      <Flex sx={_styles["text-box"]}>
        {text?.map((t, i) => (
          <Text key={t} sx={_styles.text} mt={i > 0 ? "24px" : "0px"}>
            {t}
          </Text>
        ))}
      </Flex>
    </Flex>
  )
}

export default IconText
